/* Root Variables */
:root {
  --primary-color: #fe4462;
  --background-color: black;
  --text-color: white;
  --font-size-base: 1rem;
}


/* Base styles */
html {
  font-size: var(--font-size-base);
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.2px;  /* Improves readability for music titles */
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: clamp(1rem, 2vw, 1.25rem); /* Responsive base font size */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Buttons */
button {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 2px solid var(--primary-color);
  padding: clamp(0.5rem, 2vw, 1rem) clamp(1rem, 3vw, 2rem);
  font-size: clamp(1rem, 2vw, 1.25rem);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 10px var(--primary-color);
  margin: 0.5rem clamp(0.5rem, 2vw, 1rem);
}

button:hover {
  background-color: var(--primary-color);
  color: white;
  box-shadow: 0 0 10px var(--primary-color);
}

/* Links */
.App-link {
  color: #fe4462;
  text-decoration: none;
}

.App-link:hover {
  text-decoration: underline;
}



/* Highlighted Text */
.highlight {
  color: #fe4462;
}

/* Media Player */
.media-player {
  margin: 20px 0;
}

.media-player audio,
.media-player video {
  width: 100%;
  max-width: 600px;
  outline: none;
}

/* Menu */
.menu {
  display: flex;
  justify-content: space-around;
  background-color: black;
  padding: 10px 0;
}

.menu-item {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
}

.menu-item:hover {
  background-color: #fe4462;
  color: white;
}

/* Input */
input {
  font-size: 1.2rem;
}


/* App Container */
.App {
  text-align: center;
  min-height: 100vh;
}

/* Header */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: clamp(1.1rem, 3vw, 1.5rem);
}

/* Logo */
.App-logo {
  height: clamp(150px, 40vmin, 300px);
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
