/* Reset styles for overlay */
body.overlay {
    margin: 0;
    padding: 0;
    background: transparent !important;
    overflow: hidden;
}

/* Remove any navigation elements */
.overlay nav,
.overlay header,
.overlay footer,
.overlay .logo,
.overlay .navigation {
    display: none !important;
}

/* Hide all buttons except those specifically meant for the overlay */
.overlay button:not(.overlay-control) {
    display: none !important;
} 